import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/opt/buildhome/repo/packages/paste-website/src/layouts/DefaultLayout.tsx";
import { graphql } from 'gatsby';
import { Box } from '@twilio-paste/box';
import Changelog from '@twilio-paste/theme/CHANGELOG.md';
import { ThemeObjectDisplay } from '../../../components/ThemeObjectDisplay.tsx';
import { SidebarCategoryRoutes } from '../../../constants';
import { Callout, CalloutTitle, CalloutText } from '../../../components/callout';
export const pageQuery = graphql`
  {
    allPasteThemePackage(filter: {name: {eq: "@twilio-paste/theme"}}) {
      edges {
        node {
          name
          description
          version
        }
      }
    }
    mdx(fields: {slug: {eq: "/tokens/theme-package/"}}) {
      fileAbsolutePath
      frontmatter {
        slug
        title
      }
      headings {
        depth
        value
      }
    }
  }
`;
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const ComponentHeader = makeShortcode("ComponentHeader");
const PageAside = makeShortcode("PageAside");
const ChangelogRevealer = makeShortcode("ChangelogRevealer");
const layoutProps = {
  pageQuery,
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">




    <ComponentHeader name="Theme package" categoryRoute={SidebarCategoryRoutes.TOKENS} githubUrl="https://github.com/twilio-labs/paste/tree/main/packages/paste-theme" data={props.data.allPasteThemePackage.edges} mdxType="ComponentHeader" />
    <hr></hr>
    <contentwrapper>
      <PageAside data={props.data.mdx} mdxType="PageAside" />
      <content>
        <p>{`This package allows for straight-forward access to Paste design tokens via a Theme Provider to React components. By using this package, you can express your UIs with each of our supported themes, and get their associated token values.`}</p>
        <h2>{`Theme`}</h2>
        <p>{`Paste components are built using our Styling Library, which currently wraps `}<a parentName="p" {...{
            "href": "https://emotion.sh/"
          }}>{`Emotion`}</a>{` and
`}<a parentName="p" {...{
            "href": "https://styled-system.com"
          }}>{`Styled System`}</a>{`. Our Styling Library provide a `}<strong parentName="p">{`Theme object`}</strong>{` containing our
design tokens. This enables our components to be themed, and also provide styling properties to some of
our design primtive components like `}<inlineCode parentName="p">{`Box`}</inlineCode>{` and `}<inlineCode parentName="p">{`Text`}</inlineCode>{`. The theme object is grouped by categories,
and within each category a camelCase key represents a design token name. To see our supported themes
and their values, check out the `}<a parentName="p" {...{
            "href": "/tokens/"
          }}>{`Token List`}</a>{` page.`}</p>
        <p>{`For example: If you were looking for token `}<inlineCode parentName="p">{`$color-background-success`}</inlineCode>{`, you would see it listed on key `}<inlineCode parentName="p">{`backgroundColors`}</inlineCode>{` as `}<inlineCode parentName="p">{`colorBackgroundSuccess`}</inlineCode>{` i.e. `}<inlineCode parentName="p">{`theme.backgroundColors.colorBackgroundSuccess`}</inlineCode>{`.`}</p>
        <p>{`The shape of the Theme object currently looks like this:`}</p>
        <ThemeObjectDisplay mdxType="ThemeObjectDisplay" />
        <h2>{`Theme Provider`}</h2>
        <p>{`The Paste `}<inlineCode parentName="p">{`ThemeProvider`}</inlineCode>{` leverages React's `}<a parentName="p" {...{
            "href": "https://reactjs.org/docs/context.html"
          }}>{`Context`}</a>{` to provide
the theme object to any descendant components in the tree. For that reason, we recommend wrapping your
application at the root level with the Paste `}<inlineCode parentName="p">{`ThemeProvider`}</inlineCode>{`. This allows all sub-components to retrieve
the correct token value for the supplied theme.`}</p>
        <pre><code parentName="pre" {...{
            "className": "language-js"
          }}>{`import {Theme} from '@twilio-paste/theme';

<Theme.Provider theme="console">
  <App />
</Theme.Provider>;
`}</code></pre>
        <h3>{`Props`}</h3>
        <table>
          <thead parentName="table">
            <tr parentName="thead">
              <th parentName="tr" {...{
                "align": null
              }}>{`Prop`}</th>
              <th parentName="tr" {...{
                "align": null
              }}>{`Type`}</th>
              <th parentName="tr" {...{
                "align": null
              }}>{`Description`}</th>
              <th parentName="tr" {...{
                "align": null
              }}>{`Default`}</th>
            </tr>
          </thead>
          <tbody parentName="table">
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}>{`theme?`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`enum`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`'default', 'console', 'sendgrid'`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`'default'`}</td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}>{`customBreakpoints?`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`string[]`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`An optional array of string values for custom screen sizes in the usual CSS width formats`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`theme.breakpoints`}</td>
            </tr>
          </tbody>
        </table>
        <h2>{`Using tokens in custom components`}</h2>
        <p>{`Sometimes you will need to create something custom that is not available in Paste but you need that component to still look like it's from Paste. The use of Paste Tokens in your styles is the way that you can access global design properties used in all Paste Components.`}</p>
        <h3>{`Styled Component`}</h3>
        <p>{`By using the `}<inlineCode parentName="p">{`Theme.Provider`}</inlineCode>{`, when you create a custom component using Styling-Library, the theme object is available on `}<inlineCode parentName="p">{`props`}</inlineCode>{` via context. You can access those via the `}<a parentName="p" {...{
            "href": "https://github.com/styled-system/styled-system/blob/master/packages/css/README.md"
          }}><inlineCode parentName="a">{`css`}</inlineCode>{` utility`}</a>{` or props object directly.`}</p>
        <p>{`This is the preferred method.`}</p>
        <pre><code parentName="pre" {...{
            "className": "language-js"
          }}>{`import {styled, css} from '@twilio-paste/styling-library';

const custom = styled.div(
  css({
    backgroundColor: 'colorBackgroundPrimary',
    padding: 'spacing20',
  })
);
`}</code></pre>
        <h3>{`Alternatives when not using Emotion`}</h3>
        <Callout variant="warning" mdxType="Callout">
  <CalloutTitle as="h4" mdxType="CalloutTitle">Careful!</CalloutTitle>
  <CalloutText mdxType="CalloutText">
    This is not a recommended approach for using Tokens. If you must do so, we strongly recommend reaching out at{' '}
    <em>#help-design-system</em>
  </CalloutText>
        </Callout>
        <p>{`If you are not using Styled Components or want to access the values of tokens in your React components outside of styling, there are three ways this can be achieved.`}</p>
        <h4>{`Paste ThemeConsumer`}</h4>
        <p>{`Paste Theme provides `}<inlineCode parentName="p">{`Theme.Consumer`}</inlineCode>{` - a React Context Consumer for the theme. It takes a function as a child, which provides the theme object as an argument.`}</p>
        <pre><code parentName="pre" {...{
            "className": "language-js"
          }}>{`import {Theme} from '@twilio-paste/theme';

<Theme.Consumer>
  {({theme}) => {
    return <p>What is the default text color {theme.textColors.colorText}</p>;
  }}
</Theme.Consumer>;
`}</code></pre>
        <h4>{`Paste useTheme Hook`}</h4>
        <p>{`Paste Theme provides a React Hook called `}<inlineCode parentName="p">{`useTheme`}</inlineCode>{` which returns the theme object from the React Context via the Theme Provider.`}</p>
        <pre><code parentName="pre" {...{
            "className": "language-js"
          }}>{`import React from 'react';
import {useTheme} from '@twilio-paste/theme';

const HookExampleComponent = (): React.ReactElement => {
  const theme = useTheme();
  return <p>What is the default text color {theme.textColors.colorText}</p>;
};
`}</code></pre>
        <h3>{`Higher Order Component (HoC)`}</h3>
        <p>{`Paste also provides a HoC to be able to access the theme object.`}</p>
        <pre><code parentName="pre" {...{
            "className": "language-js"
          }}>{`import React from 'react';
import {withTheme} from '@twilio-paste/theme';

const ExampleComponent = ({theme}) => <p>What is the default text color {theme.textColors.colorText}</p>;

// Provides this component with the theme object as a prop
const ExampleComponentwithTheme = withTheme(ExampleComponent);
`}</code></pre>
        <h2>{`Give us Feedback on this Page`}</h2>
        <p>{`As you use Paste, you'll likely encounter things that `}<em parentName="p">{`don't seem right`}</em>{`. Please reach out with your feedback! Here's some prompts to consider:`}</p>
        <ul>
          <li parentName="ul">{`Is this page easy for me to consume?`}</li>
          <li parentName="ul">{`Is the information supporting it sufficient / well-described?`}</li>
          <li parentName="ul">{`What information is missing?`}</li>
          <li parentName="ul">{`How approachable is the documentation? Can a new engineer / PM / designer at Twilio get started with it easily?`}</li>
        </ul>
        <hr></hr>
        <ChangelogRevealer mdxType="ChangelogRevealer">
  <Changelog mdxType="Changelog" />
        </ChangelogRevealer>
      </content>
    </contentwrapper>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      